import React from 'react';
import connect from '@vkontakte/vkui-connect';
import { Panel, ListItem, Button, Group, Div, Avatar, PanelHeader, PanelHeaderContent, HeaderButton, platform } from '@vkontakte/vkui';
import Icon28Menu from '@vkontakte/icons/dist/28/menu';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';


import '../css/Main.css';

const osname = platform();

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    backApp = () => { this.props.history.go(-1) }

    componentDidMount() {
        document.body.style = "--header_background: #87C2E7; --header_alternate_background: #87C2E7; --background_page: #BEE4F7;";
        connect.send("VKWebAppSetViewSettings", { "status_bar_style": "dark", "action_bar_color": "#87C2E7" });
    }

    render() {
        return (
            <Panel id={this.props.id}>
                <PanelHeader
                    left={
                        <HeaderButton onClick={() => { this.props.goBack() }}>
                            {osname === "ios" ? <Icon28ChevronBack /> : <Icon24Back />}
                        </HeaderButton>
                    }
                >
                    <PanelHeaderContent>
                        <div className="header-title">
                            <span>О приложении</span>
                        </div>
                    </PanelHeaderContent>
                    
                </PanelHeader>
                <Div>
                    <div className="main-title">
                        <div id="donor" >
                            <div id="donor-img"><img src="./part/donor.png" alt="donor" /></div>
                            <div id="donor-bt">
                                <span>Сообщество доноров крови - DonorSearch.org</span>
                                <a href="https://vk.com/donorsearch" target="_blank">Перейти в сообщество</a>
                            </div>
                        </div>
                        <div id="donor" >
                            <div id="luna-img"><img src="./part/logoluanapps.svg" alt="luna" /></div>
                            <div id="donor-bt">
                                <span>Сервис разработан командой LunaApps</span>
                                <a href="https://vk.com/lunaaps" target="_blank">Перейти в сообщество</a>
                            </div>
                        </div>
                        {/* /////////////////////////////////////////////////////////////////////////// */}
                        <div id="main-part">
                            <p>Партнеры</p>
                            <div>
                                <a id="pgrants" href="https://vk.com/pgrants" target="_blank">
                                    <div>
                                        <div>
                                            <img src="./part/pgrants_logo.svg" alt="pgrants" />
                                        </div>
                                    </div>
                                </a>
                                <a id="pgrants" href=" https://vk.com/nfrzmoscow" target="_blank">
                                    <div>
                                        <div>
                                            <img src="./part/logo_png.png" alt="nfrzmoscow" />
                                        </div>
                                    </div>
                                </a>
                                <a id="obrazfund" href="https://vk.com/obrazfund" target="_blank">
                                    <div>
                                        <div>
                                            <img src="./part/logo.png" alt="obrazfund" />
                                        </div>
                                    </div>
                                </a>
                                <a id="obrazfund" href="https://vk.com/volmedic" target="_blank">
                                    <div>
                                        <div>
                                            <img src="./part/25930.jpg" alt="volmedic" />
                                        </div>
                                    </div>
                                </a>

                            </div>
                        </div>

                    </div>
                    <div className="main-bt-block">

                    </div>
                </Div>
            </Panel>
        );
    }
}

export default Main;