import React from 'react';
import axios from 'axios';
import connect from '@vkontakte/vkui-connect';
// import connect from '@vkontakte/vkui-connect-mock';
import { View, ConfigProvider, platform } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import First from './panels/js/First';
import Main from './panels/js/Main';
import Crossword from './panels/js/Crossword';
import Victo from './panels/js/Victo';
import NewWord from './panels/js/NewWord';
import Rating from './panels/js/Rating';
import Partner from './panels/js/Partner';
import { ReactComponent as Heart } from './img/heart.svg';

import './App.css';
import './Menu.css';

const osname = platform();
var close = null;
var showMenu = false;


class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			UrlServer: 'https://erudyt.donorsearch.org',
			hr: null,
			activePanel: 'main',
			history: ['main'],
			loadApp: false,
			fetchedUser: null,
			showMenu: false,
			appGroupId: null,
			infoApp: null,
			userInfo: null,
			cities: null,
			exitToo: false,
			token: null,
			btjoingr: true,
			online: true,
			first: true,
		};
	}

	chProps = (prop, val) => {
		this.setState({ [prop]: val });
	}

	online = () => {
		this.setState({ online: true });
		connect.send('VKWebAppGetUserInfo', {});
	}

	offline = () => {
		this.setState({ online: false });
	}

	componentDidMount() {
		window.addEventListener('online', this.online);
		window.addEventListener('offline', this.offline);
		var self = this;
		this.setState({ hr: this.props.hr });
		//////////////////////////////////////////////////////////
		var a = this.props.infoApp[0].split('=');
		var temp = '{ "infoApp": { "' + a[0] + '": "' + a[1] + '"';
		for (let i = 1; i < this.props.infoApp.length; i++) {
			var a = this.props.infoApp[i].split('=');
			if (a[0] == 'vk_group_id') {
				this.setState({ appGroupId: a[1] });
			}
			temp += ', "' + a[0] + '": "' + a[1] + '"'
		}
		temp += '} }'
		this.setState({ infoApp: JSON.parse(temp) });
		///////////////////////////////////////////////////////////
		window.addEventListener('popstate', (event) => {
			const his = [...this.state.history];
			his.pop();
			const active = his[his.length - 1];
			if (active != undefined) {
				window.location.hash = 'act=' + active;

				if (active === 'first') {
					connect.send('VKWebAppDisableSwipeBack');
				}
				this.setState({ history: his, activePanel: active });
			} else {
				this.setState({ exitToo: true });
				if (close != null) clearTimeout(close);
				close = setTimeout(() => {
					window.history.pushState({}, '', '#act=first');
					this.setState({ exitToo: false });
					close = null;
				}, 4000)

			}
		}, false);
		window.history.pushState({}, '', '#act=main');
		connect.subscribe((e) => {
			switch (e.detail.type) {
				case 'VKWebAppJoinGroupResult':
					if (e.detail.data.result) {
						var a = "updategropupt" +
							'&' + String(this.state.userInfo.id);
						var p = btoa(a);
						axios.get(this.state.UrlServer + '/backend/apiDonor.php', { params: { p: p, u: btoa(this.state.hr) }, })
							.then(function (response) {
								if (response.data) {
									self.setState({ btjoingr: false });
								}
							});
					}
					break;
				case 'VKWebAppGetUserInfoResult':
					this.setState({ fetchedUser: e.detail.data });
					var city = "";
					if (e.detail.data.city) {
						city = e.detail.data.city.title;
					}
					console.log(e.detail.data);
					var bdate = e.detail.data.bdate;
					if (bdate == undefined) {
						bdate = "01.01.0001";
					}
					var a = "login" +
						'&' + String(e.detail.data.id) +
						'&' + btoa(unescape(encodeURIComponent(e.detail.data.first_name))) +
						'&' + btoa(unescape(encodeURIComponent(e.detail.data.last_name))) +
						'&' + btoa(unescape(encodeURIComponent(city))) +
						'&' + String(bdate) +
						'&' + String(e.detail.data.timezone) +
						'&' + String(e.detail.data.photo_200 != null ? e.detail.data.photo_200 : "https://vk.com/images/camera_200.png?ava=1");
					var p = btoa(a);
					console.log(a, this.props.hr);
					axios.get(this.state.UrlServer + '/backend/apiDonor.php', { params: { p: p, u: btoa(this.props.hr) }, })
						.then(function (response) {
							console.log('response with params: { p: p, u: btoa(this.props.hr) }', response)
							var a = "ismember" + '&' + String(e.detail.data.id) +
								'&' + String(e.detail.data.appGroupId);
							var p = btoa(a);
							axios.get(self.state.UrlServer + '/backend/apiDonor.php', { params: { p: p, u: btoa(self.props.hr) }, })
								.then(function (response) {
									// console.log("qwe", response.data);
									if (response.data.response == 1) {
										self.setState({ btjoingr: false });
									} else {
										self.setState({ btjoingr: true });
									}
								});
							if (response.data.first) {
								self.setState({ activePanel: "first" , loadApp: true});
							}
							if (self.state.appGroupId != null && self.state.appGroupId != response.data.data.gropup_id) {
								var a = "updategropupid" + '&' + String(e.detail.data.id) +
									'&' + String(self.state.appGroupId);
								var p = btoa(a);
								axios.get(self.state.UrlServer + '/backend/apiDonor.php', { params: { p: p, u: btoa(self.state.hr) }, })
									.then(function (response) { });
								self.setState({
									userInfo: {
										id: response.data.data.id,
										f_name: decodeURIComponent(escape(atob(response.data.data.f_name))),
										l_name: decodeURIComponent(escape(atob(response.data.data.l_name))),
										city: decodeURIComponent(escape(atob(response.data.data.city))),
										bdate: response.data.data.bdate,
										gropup_id: self.state.appGroupId,
										ball_user: response.data.data.ball_user
									},
									loadApp: true
								});
							} else {
								self.setState({
									userInfo: {
										id: response.data.data.id,
										f_name: decodeURIComponent(escape(atob(response.data.data.f_name))),
										l_name: decodeURIComponent(escape(atob(response.data.data.l_name))),
										city: decodeURIComponent(escape(atob(response.data.data.city))),
										bdate: response.data.data.bdate,
										gropup_id: response.data.data.gropup_id,
										ball_user: response.data.data.ball_user
									},
									loadApp: true
								});
							}
							setTimeout(() => {
								if (osname === "android") {
									document.getElementsByClassName('menu')[0].style.paddingTop = "56px";
								}
							}, 10)

						});

					break;
				case 'VKWebAppAccessTokenReceived':
					connect.send("VKWebAppCallAPIMethod", {
						"method": "stories.getPhotoUploadServer",
						"request_id": "stories",
						"params": {
							"v": "5.101",
							"access_token": e.detail.data.access_token,
							"link_url": "https://vk.com/donorsearch",
							"add_to_news": 1
						}
					});
					break;
				case 'VKWebAppCallAPIMethodResult':
					switch (e.detail.data.request_id) {
						case 'stories':
							var a = "stories"
							var p = btoa(a);
							axios.get(self.state.UrlServer + '/backend/apiDonor.php', {
								params: {
									p: p,
									url: e.detail.data.response.upload_url, u: btoa(this.state.hr)
								}
							})
								.then(function (response) { });
							break;
						default:
							console.log(e.detail.data);
							break;
					}
					break;
				default:
					console.log(e.detail.type);
			}
		});
		connect.send('VKWebAppGetUserInfo', {});
		////////////////////////////////////////////////////////////////////////////////////////
		axios
			.get('/content/Citys.json', {
				params: {},
			})
			.then(function (response) {
				var citys = [];
				for (let i = 0; i < response.data.ListCityS.length; i++) {
					var str = response.data.ListCityS[i].city + "/ " + response.data.ListCityS[i].region + " " + response.data.ListCityS[i].regionType + ".";
					citys = citys.concat([str]);
				}
				self.setState({ cities: citys });
			});
		//////////////////////////////////////////////////////////////////////////////////////// arrCity

	}

	goBack = () => {
		window.history.back();
	}

	goForward = (activePanel) => {
		const history = [...this.state.history];
		history.push(activePanel);
		if (this.state.activePanel === 'main') {
			connect.send('VKWebAppEnableSwipeBack');
		}
		window.history.pushState({}, '', '#act=' + activePanel);
		// window.location = '#act=' + activePanel;

		this.setState({ history, activePanel });
	}

	chMenu = (val) => {
		this.setState({ showMenu: val });
		showMenu = val;
		var Menu = document.getElementsByClassName('menu');
		var Overlow = document.getElementsByClassName('overlow');
		// this.props.chProps("showMenu", val);
		if (showMenu) {
			Menu[0].style.left = "0";
			Overlow[0].style.visibility = "visible";
			Overlow[0].style.opacity = "1";
		} else {
			Menu[0].style.left = "-234px";
			Overlow[0].style.visibility = "hidden";
			Overlow[0].style.opacity = "0";
		}
	}

	render() {
		if (this.state.loadApp) {
			let exitToo;
			if (this.state.exitToo) {
				exitToo =
					<div id="exitToo" onClick={() => {
						window.history.pushState({}, '', '#act=main');
						clearTimeout(close);
						close = null;
						this.setState({ exitToo: false });
					}}>
						<span>Нажмите еще раз для выхода</span>
					</div>
			}

			// let menuItem =
			// 	<div>
			// 		<div className="menu-item" onClick={() => { this.chMenu(false); this.goForward("crossword") }}><b>Кроссворд</b></div>
			// 		<div className="menu-item" onClick={() => { this.chMenu(false); this.goForward("victo") }}><b>Викторина</b></div>
			// 		<div className="menu-item" onClick={() => { this.chMenu(false); this.goForward("newword") }}><b>Добавить слово</b></div>
			// 		<div className="menu-item" onClick={() => { this.chMenu(false); this.goForward("rating") }}><b>Рейтинг</b></div>
			// 		<div className="menu-item" onClick={() => { this.chMenu(false); this.goForward("partner") }}><b>О приложении</b></div>
			// 	</div>
			return (
				<ConfigProvider>
					<div className="wrapper">
						<div className="overlow" onClick={() => { this.chMenu(false) }}></div>
						<div className="menu">
							<div>
								<div className="menu-item" onClick={() => { this.chMenu(false); this.goForward("crossword") }}><b>Кроссворд</b></div>
								<div className="menu-item" onClick={() => { this.chMenu(false); this.goForward("victo") }}><b>Викторина</b></div>
								<div className="menu-item" onClick={() => { this.chMenu(false); this.goForward("newword") }}><b>Добавить слово</b></div>
								<div className="menu-item" onClick={() => { this.chMenu(false); this.goForward("rating") }}><b>Рейтинг</b></div>
								<div className="menu-item" onClick={() => { this.chMenu(false); this.goForward("partner") }}><b>О приложении</b></div>
								<a className="menu-item" target="_blank" href="https://vk.com/im?media=&sel=-21179173"><b>Поддержка</b></a>
							</div>
							<div className="menu-dog"></div>
						</div>
					</div>
					{exitToo}
					<View
						activePanel={this.state.activePanel}
						history={this.state.history}
						onSwipeBack={this.goBack}
						goBack={this.goBack}
						goForward={this.goForward}
					>
						<First
							fetchedUser={this.state.fetchedUser}
							showMenu={this.state.showMenu}
							id="first"
							goBack={this.goBack}
							goForward={this.goForward}
							userInfo={this.state.userInfo}
							hr={this.state.hr}
							chProps={this.chProps}
						/>
						<Main
							fetchedUser={this.state.fetchedUser}
							showMenu={this.state.showMenu}
							id="main"
							goBack={this.goBack}
							goForward={this.goForward}
							chMenu={this.chMenu}
							userInfo={this.state.userInfo}
							hr={this.state.hr}
							chProps={this.chProps}
							btjoingr={this.state.btjoingr}
							first={this.state.first}
						/>
						<Partner
							fetchedUser={this.state.fetchedUser}
							showMenu={this.state.showMenu}
							id="partner"
							goBack={this.goBack}
							goForward={this.goForward}
							chMenu={this.chMenu}
							userInfo={this.state.userInfo}
							hr={this.state.hr}
							chProps={this.chProps}
						/>
						<Crossword
							UrlServer={this.state.UrlServer}
							fetchedUser={this.state.fetchedUser}
							id="crossword"
							goBack={this.goBack}
							goForward={this.goForward}
							chMenu={this.chMenu}
							userInfo={this.state.userInfo}
							hr={this.state.hr}
							chProps={this.chProps}
							token={this.state.token}
						/>
						<Victo
							UrlServer={this.state.UrlServer}
							fetchedUser={this.state.fetchedUser}
							id="victo"
							goBack={this.goBack}
							goForward={this.goForward}
							chMenu={this.chMenu}
							userInfo={this.state.userInfo}
							hr={this.state.hr}
							chProps={this.chProps}
							token={this.state.token}
						/>
						<NewWord
							UrlServer={this.state.UrlServer}
							fetchedUser={this.state.fetchedUser}
							id="newword"
							goBack={this.goBack}
							goForward={this.goForward}
							chMenu={this.chMenu}
							userInfo={this.state.userInfo}
							hr={this.state.hr}
							chProps={this.chProps}
						/>
						<Rating
							UrlServer={this.state.UrlServer}
							fetchedUser={this.state.fetchedUser}
							userInfo={this.state.userInfo}
							hr={this.state.hr}
							chProps={this.chProps}
							id="rating"
							goBack={this.goBack}
							goForward={this.goForward}
							chMenu={this.chMenu}
							cities={this.state.cities}
						/>
					</View>
				</ConfigProvider>
			);
		} else {
			return (
				<div className="cssload">
					<Heart />
					{/* <div className="cssload-main">
						<div className="cssload-heart">
							<span className="cssload-heartL"></span>
							<span className="cssload-heartR"></span>
							<span className="cssload-square"></span>
						</div>
						<div className="cssload-shadow"></div>
					</div> */}
				</div>
			);
		}
	}
}

export default App;
