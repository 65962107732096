import React from 'react';
import axios from 'axios';
import connect from '@vkontakte/vkui-connect';
import Select from 'react-select';
import SwipeableViews from 'react-swipeable-views';
import { Panel, Div, PanelHeader, HeaderButton, platform, PanelHeaderContent } from '@vkontakte/vkui';
import Icon28Menu from '@vkontakte/icons/dist/28/menu';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import InputMask from 'react-input-mask';


// import Tabletop from 'tabletop'; // google !!!!!

import '../css/Rating.css';

const osname = platform();
var arrAgeOhter = [];
var arrCityOhter = [];
var arrBloodOhter = [];
var myAge = null;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            arrAge: [],
            arrCity: [],
            arrBlood: [],
            modalR: false,
            gorod: '',
            meRating: '',
            userList: null,

            bdate: '',

            infoGR: {
                id: 0,
                name: "",
                ava: "https://vk.com/images/community_200.png?1",
            },
            online: true,
        }
    }

    get_current_age(date) {
        return ((new Date().getTime() - new Date(date)) / (24 * 3600 * 365.25 * 1000)) | 0;
    }

    handleChangeIndex = index => {
        this.setState({ index });
        this.changeIndex(index);
    };

    changeIndex = (val) => {
        // var navigation1 = document.getElementsByClassName('navi-item-1');
        // var navigation2 = document.getElementsByClassName('navi-item-2');
        // var navigation3 = document.getElementsByClassName('navi-item-3');
        var naviStick = document.getElementsByClassName('navi-stick-item');
        if (val == 0) {
            naviStick[0].style.left = "0";
            ///////////////////////////////////
            var temp = this.state.arrCity;
            temp = temp.concat(arrCityOhter);
            var tempGo = [];
            var conutTemp = temp.length
            for (let i = 0; i < conutTemp; i++) {
                tempGo.push(temp.shift());
                if (i == 50) { break; }
            }
            arrCityOhter = temp;
            this.setState({ arrCity: tempGo });
            ///////////////////////////////////
            var temp = this.state.arrBlood;
            temp = temp.concat(arrBloodOhter);
            var tempGo = [];
            var conutTemp = temp.length
            for (let i = 0; i < conutTemp; i++) {
                tempGo.push(temp.shift());
                if (i == 50) { break; }
            }
            arrBloodOhter = temp;
            this.setState({ arrBlood: tempGo });
        }
        if (val == 1) {
            naviStick[0].style.left = "33.33%";
            ///////////////////////////////////
            var temp = this.state.arrAge;
            temp = temp.concat(arrAgeOhter);
            var tempGo = [];
            var conutTemp = temp.length
            for (let i = 0; i < conutTemp; i++) {
                tempGo.push(temp.shift());
                if (i == 50) { break; }
            }
            arrAgeOhter = temp;
            this.setState({ arrAge: tempGo });
            ///////////////////////////////////
            var temp = this.state.arrBlood;
            temp = temp.concat(arrBloodOhter);
            var tempGo = [];
            var conutTemp = temp.length
            for (let i = 0; i < conutTemp; i++) {
                tempGo.push(temp.shift());
                if (i == 50) { break; }
            }
            arrBloodOhter = temp;
            this.setState({ arrBlood: tempGo });
        }
        if (val == 2) {
            naviStick[0].style.left = "66.66%";
            ///////////////////////////////////
            var temp = this.state.arrCity;
            temp = temp.concat(arrCityOhter);
            var tempGo = [];
            var conutTemp = temp.length
            for (let i = 0; i < conutTemp; i++) {
                tempGo.push(temp.shift());
                if (i == 50) { break; }
            }
            arrCityOhter = temp;
            this.setState({ arrCity: tempGo });
            ///////////////////////////////////
            var temp = this.state.arrAge;
            temp = temp.concat(arrAgeOhter);
            var tempGo = [];
            var conutTemp = temp.length
            for (let i = 0; i < conutTemp; i++) {
                tempGo.push(temp.shift());
                if (i == 50) { break; }
            }
            arrAgeOhter = temp;
            this.setState({ arrAge: tempGo });
        }
    }

    onChange = (e) => {

        const { id, value } = e.currentTarget;

        if (id == "gorod") {
            var s = value.trim(); console.log("'" + s + "'", "'" + value + "'");
            var res = /[^а-яА-ЯїЇєЄіІёЁ]/g.exec(s);
            var a = s.replace(res, '');
            if (a.length < 50)
                this.setState({ [id]: a });

            document.getElementById('podskazka').style.display = "none";
            document.getElementById('podskazka1').style.display = "none";
            document.getElementById('podskazka2').style.display = "none";
            var reg = new RegExp('^' + s, 'i'),
                t = document.getElementById('podskazka'),
                t1 = document.getElementById('podskazka1'),
                t2 = document.getElementById('podskazka2');
            t.innerHTML = '';
            t1.innerHTML = '';
            t2.innerHTML = '';
            if (s.length > 0)
                for (var i = 0; i < this.props.cities.length; i++) {
                    if (reg.test(this.props.cities[i])) {
                        if (t.style.display == "none") {
                            t.innerHTML = this.props.cities[i];
                            t.style.display = "flex";
                        } else if (t1.style.display == "none") {
                            t1.innerHTML = this.props.cities[i];
                            t1.style.display = "flex";
                        } else if (t2.style.display == "none") {
                            t2.innerHTML = this.props.cities[i];
                            t2.style.display = "flex";
                            break;
                        }
                    }
                }
        } else {
            this.setState({ [id]: value });
        }
    }

    addUsersList = (arr) => {
        var self = this;
        var tempMe = [];
        var tempA = [];
        var tempOtherA = [];
        var tempC = [];
        var tempOtherC = [];
        var tempB = [];
        var tempOtherB = [];
        for (let i = 0; i < arr.length; i++) {
            var f_name = decodeURIComponent(escape(atob(arr[i].f_name)));
            var bgColor = "#FFF";
            var textColor = "#000";
            var Age = self.get_current_age(arr[i].bdate)
            if (arr[i].bdate == '0001-01-01') Age = 0;
            if (arr[i].id == self.props.userInfo.id) {
                f_name = "Вы";
                bgColor = "#E05047";
                textColor = "#FFF";
                tempMe = tempMe.concat({
                    id: arr[i].id,
                    ava: arr[i].ava,
                    f_name: f_name,
                    city: decodeURIComponent(escape(atob(arr[i].city))),
                    age: Age,
                    ball: arr[i].ball_user,
                    bgColor: bgColor,
                    textColor: textColor
                });
            }
            if (myAge == Age && Age != 0 && Age > 0 && Age < 100) {
                if (tempA.length < 50) {
                    tempA = tempA.concat({
                        id: arr[i].id,
                        ava: arr[i].ava,
                        f_name: f_name,
                        city: decodeURIComponent(escape(atob(arr[i].city))),
                        age: Age,
                        ball: arr[i].ball_user,
                        bgColor: bgColor,
                        textColor: textColor
                    })
                } else {
                    tempOtherA = tempOtherA.concat({
                        id: arr[i].id,
                        ava: arr[i].ava,
                        f_name: f_name,
                        city: decodeURIComponent(escape(atob(arr[i].city))),
                        age: Age,
                        ball: arr[i].ball_user,
                        bgColor: bgColor,
                        textColor: textColor
                    })
                }
            }
            if (self.props.userInfo.city == decodeURIComponent(escape(atob(arr[i].city)))) {
                if (tempC.length < 50) {
                    tempC = tempC.concat({
                        id: arr[i].id,
                        ava: arr[i].ava,
                        f_name: f_name,
                        city: decodeURIComponent(escape(atob(arr[i].city))),
                        age: Age,
                        ball: arr[i].ball_user,
                        bgColor: bgColor,
                        textColor: textColor
                    })
                } else {
                    tempOtherC = tempOtherC.concat({
                        id: arr[i].id,
                        ava: arr[i].ava,
                        f_name: f_name,
                        city: decodeURIComponent(escape(atob(arr[i].city))),
                        age: Age,
                        ball: arr[i].ball_user,
                        bgColor: bgColor,
                        textColor: textColor
                    })
                }
            }
            if (self.props.userInfo.gropup_id == arr[i].gropup_id && self.props.userInfo.gropup_id != "" && self.props.userInfo.gropup_id != null && self.props.userInfo.gropup_id != 0) {
                if (tempB.length < 50) {
                    tempB = tempB.concat({
                        id: arr[i].id,
                        ava: arr[i].ava,
                        f_name: f_name,
                        city: decodeURIComponent(escape(atob(arr[i].city))),
                        age: Age,
                        ball: arr[i].ball_user,
                        bgColor: bgColor,
                        textColor: textColor
                    })
                } else {
                    tempOtherB = tempOtherB.concat({
                        id: arr[i].id,
                        ava: arr[i].ava,
                        f_name: f_name,
                        city: decodeURIComponent(escape(atob(arr[i].city))),
                        age: Age,
                        ball: arr[i].ball_user,
                        bgColor: bgColor,
                        textColor: textColor
                    })
                }
            }
        }
        arrAgeOhter = tempOtherA
        arrCityOhter = tempOtherC
        arrBloodOhter = tempOtherB
        self.setState({
            arrAge: tempA,
            arrCity: tempC,
            arrBlood: tempB,
            meRating: tempMe
        });
    }

    online = () => {
        var self = this;
        var a = "getlistusers";
        var p = btoa(a);
        axios.get(self.props.UrlServer + '/backend/apiDonor.php', { params: { p: p, u: btoa(this.props.hr) }, })
            .then(function (response) {
                self.setState({ userList: response.data });
                self.addUsersList(response.data);
            });
        this.setState({ online: true });
    }

    offline = () => {
        this.setState({ online: false, modalR: false });
    }

    componentDidMount() {
        window.addEventListener('online', this.online);
        window.addEventListener('offline', this.offline);
        if (!navigator.onLine) {
            this.offline();
        } else {
            this.online();
        }

        var self = this;
        if (this.props.userInfo.bdate != '0001-01-01') {
            myAge = this.get_current_age(this.props.userInfo.bdate);
        } else {
            myAge = 0;
        }

        connect.send("VKWebAppSetViewSettings", { "status_bar_style": "dark", "action_bar_color": "#87C2E7" });
        if (osname === "android") {
            var slideList = document.getElementsByClassName('rating-list');
            setTimeout(() => {
                for (let i = 0; i < slideList.length; i++) {
                    slideList[i].style.height = "calc(100vh - 170px - 70px)";
                }
            }, 10);
        }
        if (this.props.userInfo.gropup_id != 0) {
            var a = "getinfogroup" +
                '&' + String(this.props.userInfo.gropup_id);
            var p = btoa(a);
            axios.get(self.props.UrlServer + '/backend/apiDonor.php', { params: { p: p, u: btoa(self.props.hr) }, })
                .then(function (response) {
                    self.setState({
                        infoGR: {
                            id: response.data.id,
                            name: response.data.name,
                            ava: response.data.photo_200,
                        }
                    });
                });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.online);
        window.removeEventListener('offline', this.offline);
    }

    copy = (id) => {
        if (id == 0) {
            var arr = document.getElementById('podskazka').innerHTML.split('/');
            var a = document.getElementById('gorod').value = arr[0];
            this.setState({ gorod: arr[0] });
        }
        if (id == 1) {
            var arr = document.getElementById('podskazka1').innerHTML.split('/');
            var a = document.getElementById('gorod').value = arr[0];
            this.setState({ gorod: arr[0] });
        }
        if (id == 2) {
            var arr = document.getElementById('podskazka2').innerHTML.split('/');
            var a = document.getElementById('gorod').value = arr[0];
            this.setState({ gorod: arr[0] });
        }

        document.getElementById('podskazka').innerHTML = '';
        document.getElementById("podskazka").style.display = "none";
        document.getElementById('podskazka1').innerHTML = '';
        document.getElementById("podskazka1").style.display = "none";
        document.getElementById('podskazka2').innerHTML = '';
        document.getElementById("podskazka2").style.display = "none";
    }

    openModalR = (title, content, btClick) => {
        this.setState({
            modalR: true,
            modalRIn: {
                title,
                content,
                btClick
            }
        });
    }

    openBdate = () => {
        var date = new Date();
        var yearNow = date.getFullYear();
        /////////////////////////////////////////////

        let daysT = []
        for (let d = 1; d < 32; d++) {
            daysT = daysT.concat([d]);
        }
        let days = daysT.map((data) =>
            <option value={data}>{data}</option>
        );
        /////////////////////////////////////////////
        let MonthT = []
        for (let m = 1; m < 13; m++) {
            MonthT = MonthT.concat([m]);
        }
        let Month = MonthT.map((data) =>
            <option value={data}>{data}</option>
        );
        /////////////////////////////////////////////
        let YaerT = []
        for (let y = yearNow; y > yearNow - 50; y--) {
            YaerT = YaerT.concat([y]);
        }
        let Yaer = YaerT.map((data) =>
            <option value={data}>{data}</option>
        );
        var title = "Введите дату рождения";
        let content =
            <div>
                <div className="bdate">
                    <InputMask
                        id="bdate"
                        className="input_input"
                        mask="9999-99-99"
                        maskChar='_'
                        type="tel"
                        placeholder="YYYY-MM-DD"
                        onChange={this.onChange}
                        onClick={() => { document.getElementById('bdate').style.border = "" }}
                    />
                </div>
                {/* <table className="tableMBD">
                    <tr> <td>День</td> <td>Месяц</td> <td>Год</td> </tr>
                    <tr>
                        <td> <select onChange={this.onChange} id="selectD"> {days} </select> </td>
                        <td> <select onChange={this.onChange} id="selectM"> {Month} </select> </td>
                        <td> <select onChange={this.onChange} id="selectY"> {Yaer} </select> </td>
                    </tr>
                </table> */}
            </div>;
        let btClick = () => {
            var self = this;
            console.log(this.get_current_age(this.state.bdate));
            // console.log(this.get_current_age(this.state.bdate));
            if (this.get_current_age(this.state.bdate) < 5 || this.get_current_age(this.state.bdate) > 100) {
                console.log(this.get_current_age(this.state.bdate) > 5 && this.get_current_age(this.state.bdate) < 100, this.get_current_age(this.state.bdate) > 5, this.get_current_age(this.state.bdate) < 100);
                document.getElementById('bdate').style.border = "1px solid #E05047"
            } else {
                this.setState({ modalR: false });
                // var strBD = this.state.selectY + "-" + this.state.selectM + "-" + this.state.selectD;
                var a = "updatebdate" +
                    '&' + String(this.props.userInfo.id) +
                    '&' + String(this.state.bdate);
                var p = btoa(a);
                axios.get(this.props.UrlServer + '/backend/apiDonor.php', { params: { p: p, u: btoa(this.props.hr) }, })
                    .then(function (response) {
                        if (response.data) {
                            var val = {
                                id: self.props.userInfo.id,
                                f_name: self.props.userInfo.f_name,
                                l_name: self.props.userInfo.l_name,
                                city: self.props.userInfo.city,
                                bdate: self.state.bdate,
                                gropup_id: self.props.userInfo.gropup_id,
                                ball_user: self.props.userInfo.ball_user
                            }

                            self.props.chProps("userInfo", val);
                            var a = "getlistusers";
                            var p = btoa(a);
                            axios.get(self.props.UrlServer + '/backend/apiDonor.php', { params: { p: p, u: btoa(self.props.hr) }, })
                                .then(function (response) {
                                    var a1
                                    if (self.props.userInfo.bdate != '0001-01-01') {
                                        a1 = self.get_current_age(self.props.userInfo.bdate);
                                    } else {
                                        a1 = 0;
                                    }
                                    myAge = a1;
                                    self.setState({ userList: response.data });
                                    self.addUsersList(response.data);
                                });
                        }
                    });
            }

        }
        this.openModalR(title, content, btClick);
    }

    openCity = () => {
        var self = this;
        var title = "Введите свой город";
        let content =
            <div >
                <input id="gorod" onChange={this.onChange} onClick={() => { document.getElementById('gorod').style.border = "" }}></input>
                <div className="pod">
                    <div id="podskazka" onClick={() => { this.copy(0) }} />
                    <div id="podskazka1" onClick={() => { this.copy(1) }} />
                    <div id="podskazka2" onClick={() => { this.copy(2) }} />
                </div>
            </div>;
        let btClick = () => {
            var reg = new RegExp('^' + this.state.gorod, 'i');
            var go = false;
            for (var i = 0; i < this.props.cities.length; i++) {
                if (reg.test(this.props.cities[i]) && this.state.gorod.length > 0) {
                    var arr = this.props.cities[i].split('/');
                    var a = arr[0];
                    if (a == this.state.gorod) go = true;
                }
            }
            // if (this.props.cities)
            if (go) {
                var a = "updatecity" +
                    '&' + String(this.props.userInfo.id) +
                    '&' + btoa(unescape(encodeURIComponent(this.state.gorod)));
                var p = btoa(a);
                axios.get(this.props.UrlServer + '/backend/apiDonor.php', { params: { p: p, u: btoa(this.props.hr) }, })
                    .then(function (response) {
                        self.setState({ modalR: false });
                        if (response.data) {
                            var val = {
                                id: self.props.userInfo.id,
                                f_name: self.props.userInfo.f_name,
                                l_name: self.props.userInfo.l_name,
                                city: self.state.gorod,
                                bdate: self.props.userInfo.bdate,
                                gropup_id: self.props.userInfo.gropup_id,
                                ball_user: self.props.userInfo.ball_user
                            }
                            self.props.chProps("userInfo", val);
                            var a = "getlistusers";
                            var p = btoa(a);
                            axios.get(self.props.UrlServer + '/backend/apiDonor.php', { params: { p: p, u: btoa(self.props.hr) }, })
                                .then(function (response) {
                                    self.setState({ userList: response.data });
                                    self.addUsersList(response.data);
                                });
                        }
                    });
            } else {
                console.log("city no");
                document.getElementById("gorod").style.border = "1px solid #E05047";
            }
        }
        this.openModalR(title, content, btClick);
    }

    selcetBlood = (id) => {
        document.getElementById("B1p").style.background = "#E05047";
        document.getElementById("B1m").style.background = "#4779BC";
        document.getElementById("B2p").style.background = "#E05047";
        document.getElementById("B2m").style.background = "#4779BC";
        document.getElementById("B3p").style.background = "#E05047";
        document.getElementById("B3m").style.background = "#4779BC";
        document.getElementById("B4p").style.background = "#E05047";
        document.getElementById("B4m").style.background = "#4779BC";
        document.getElementById("Bhz").style.background = "#E05047";
        this.setState({ blood: id })
        document.getElementById(id).style.background = "#37AE46";
    }

    openBlood = () => {
        var title = "Выберите группу крови";
        let content =
            <div >
                <div className="blood-block">
                    <button onClick={() => { this.selcetBlood("B1p") }} id="B1p" className="blood-item" style={{ background: "#E05047" }}>I (0) Rh(+)</button>
                    <button onClick={() => { this.selcetBlood("B1m") }} id="B1m" className="blood-item" style={{ background: "#4779BC" }}>I (0) rh(-)</button>
                    <button onClick={() => { this.selcetBlood("B2p") }} id="B2p" className="blood-item" style={{ background: "#E05047" }}>II (A) Rh(+)</button>
                    <button onClick={() => { this.selcetBlood("B2m") }} id="B2m" className="blood-item" style={{ background: "#4779BC" }}>II (A) rh(-)</button>
                    <button onClick={() => { this.selcetBlood("B3p") }} id="B3p" className="blood-item" style={{ background: "#E05047" }}>III (B) Rh(+)</button>
                    <button onClick={() => { this.selcetBlood("B3m") }} id="B3m" className="blood-item" style={{ background: "#4779BC" }}>III (B) rh(-)</button>
                    <button onClick={() => { this.selcetBlood("B4p") }} id="B4p" className="blood-item" style={{ background: "#E05047" }}>IV (AB) Rh(+)</button>
                    <button onClick={() => { this.selcetBlood("B4m") }} id="B4m" className="blood-item" style={{ background: "#4779BC" }}>IV (AB) rh(-)</button>
                    <button onClick={() => { this.selcetBlood("Bhz") }} id="Bhz" className="blood-item" style={{ background: "#E05047" }}>Я не знаю</button>
                </div>
            </div>;
        let btClick = () => {
            // this.setState({ modalR: false });э



        }
        this.openModalR(title, content, btClick);
    }

    scrollList = (idList) => {
        var list = document.getElementById(idList);
        switch (idList) {
            case "arrAge":
                if (list.offsetHeight + list.scrollTop >= list.scrollHeight - 10) {
                    var temp = this.state.arrAge;
                    for (let i = 0; i < arrAgeOhter.length; i++) {
                        if (i < 50) {
                            temp.push(arrAgeOhter.shift());
                        } else { break; }

                    }
                    this.setState({ [idList]: temp })
                }
                break;
            case "arrCity":
                if (list.offsetHeight + list.scrollTop >= list.scrollHeight - 10) {
                    var temp = this.state.arrCity;
                    for (let i = 0; i < arrCityOhter.length; i++) {
                        if (i < 50) {
                            temp.push(arrCityOhter.shift());
                        } else { break; }

                    }
                    this.setState({ [idList]: temp })
                }
                break;
            case "arrBlood":
                if (list.offsetHeight + list.scrollTop >= list.scrollHeight - 10) {
                    var temp = this.state.arrBlood;
                    for (let i = 0; i < arrBloodOhter.length; i++) {
                        if (i < 50) {
                            temp.push(arrBloodOhter.shift());
                        } else { break; }

                    }
                    this.setState({ [idList]: temp })
                }
                break;
            default:
                break;
        }

    }

    declOfNum = (number, titles) => {
        var cases = [2, 0, 1, 1, 1, 2];
        return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }

    render() {
        ///////////////////////////////////////////////////////////////////////////////////////////
        let meRatingA, meRatingNoneA;
        let arrAge, arrAgeNone
        if (this.state.arrAge.length != 0) {
            if (this.state.meRating.length != 0) {
                meRatingA = this.state.meRating.map((data) =>
                    <div className="rating-list-item" style={{ background: data.bgColor, color: data.textColor, border: "none" }}>
                        <div className="rating-list-item-l">
                            <div className="rating-list-item-l-l">
                                <img src={data.ava} alt="avatar" />
                            </div>
                            <div className="rating-list-item-l-r">
                                <div className="rating-list-item-l-r-t">
                                    <b>{data.f_name}, {data.age} {this.declOfNum(data.age, ["год", "года", "лет"])}</b>
                                </div>
                                <div className="rating-list-item-l-r-b">
                                    {data.city}
                                </div>
                            </div>
                        </div>
                        <div className="rating-list-item-r">
                            <b>{data.ball}</b>
                        </div>
                    </div>
                );
            } else {
                meRatingNoneA =
                    <div className="meRatingNone">
                        <p>Вас нет в рейтинге</p>
                        <span>*Чтобы попасть в рейтинг, необходимо набрать хотя бы один балл</span>
                    </div>
            }
            arrAge = this.state.arrAge.map((data) =>
                <div className="rating-list-item" style={{ background: data.bgColor, color: data.textColor }}>
                    <div className="rating-list-item-l">
                        <div className="rating-list-item-l-l">
                            <img src={data.ava} alt="avatar" />
                        </div>
                        <div className="rating-list-item-l-r">
                            <div className="rating-list-item-l-r-t">
                                <b>{data.f_name}, {data.age} {this.declOfNum(data.age, ["год", "года", "лет"])}</b>
                            </div>
                            <div className="rating-list-item-l-r-b">
                                {data.city}
                            </div>
                        </div>
                    </div>
                    <div className="rating-list-item-r">
                        <b>{data.ball}</b>
                    </div>
                </div>
            );
        } else {
            arrAgeNone =
                <div className="ListNone">
                    <div className="ListNone-conent">
                        <p>Чтобы попасть в этот рейтинг, введите дату своего дня рождения и наберите хотя бы один балл</p>
                    </div>
                </div>
        }
        ///////////////////////////////////////////////////////////////////////////////////////////
        let meRatingC, meRatingNoneC;
        let arrCity, arrCityNone
        if (this.state.arrCity.length != 0) {
            if (this.state.meRating.length != 0) {
                meRatingC = this.state.meRating.map((data) =>
                    <div className="rating-list-item" style={{ background: data.bgColor, color: data.textColor, border: "none" }}>
                        <div className="rating-list-item-l">
                            <div className="rating-list-item-l-l">
                                <img src={data.ava} alt="avatar" />
                            </div>
                            <div className="rating-list-item-l-r">
                                <div className="rating-list-item-l-r-t">
                                    <b>{data.f_name}, {data.age} {this.declOfNum(data.age, ["год", "года", "лет"])}</b>
                                </div>
                                <div className="rating-list-item-l-r-b">
                                    {data.city}
                                </div>
                            </div>
                        </div>
                        <div className="rating-list-item-r">
                            <b>{data.ball}</b>
                        </div>
                    </div>
                );
            } else {
                meRatingNoneC =
                    <div className="meRatingNone">
                        <p>Вас нет в рейтинге</p>
                        <span>*Чтобы попасть в рейтинг, необходимо набрать хотя бы один балл</span>
                    </div>
            }
            arrCity = this.state.arrCity.map((data) =>
                <div className="rating-list-item" style={{ background: data.bgColor, color: data.textColor }}>
                    <div className="rating-list-item-l">
                        <div className="rating-list-item-l-l">
                            <img src={data.ava} alt="avatar" />
                        </div>
                        <div className="rating-list-item-l-r">
                            <div className="rating-list-item-l-r-t">
                                <b>{data.f_name}, {data.age} {this.declOfNum(data.age, ["год", "года", "лет"])}</b>
                            </div>
                            <div className="rating-list-item-l-r-b">
                                {data.city}
                            </div>
                        </div>
                    </div>
                    <div className="rating-list-item-r">
                        <b>{data.ball}</b>
                    </div>
                </div>
            );
        } else {
            arrCityNone =
                <div className="ListNone">
                    <div className="ListNone-conent">
                        <p>Чтобы попасть в этот рейтинг, выберете свой город и наберите хотя бы один балл</p>
                    </div>
                </div>
        }
        ///////////////////////////////////////////////////////////////////////////////////////////
        let meRatingB, meRatingNoneB;
        let arrBlood, BloodNone;
        if (this.state.arrBlood.length != 0) {
            if (this.state.index == 2) {
                if (this.state.infoGR.id != 0 && this.state.infoGR.id != undefined) {
                    btTitle =
                        <a href={"https://vk.com/club" + this.state.infoGR.id} className="rating-title-a">
                            <div>
                                <img src={this.state.infoGR.ava} alt="logo" />
                                <span>{this.state.infoGR.name}</span>
                            </div>
                        </a>
                }
            }
            if (this.state.meRating.length != 0) {
                meRatingB = this.state.meRating.map((data) =>
                    <div className="rating-list-item" style={{ background: data.bgColor, color: data.textColor, border: "none" }}>
                        <div className="rating-list-item-l">
                            <div className="rating-list-item-l-l">
                                <img src={data.ava} alt="avatar" />
                            </div>
                            <div className="rating-list-item-l-r">
                                <div className="rating-list-item-l-r-t">
                                    <b>{data.f_name}, {data.age} {this.declOfNum(data.age, ["год", "года", "лет"])}</b>
                                </div>
                                <div className="rating-list-item-l-r-b">
                                    {data.city}
                                </div>
                            </div>
                        </div>
                        <div className="rating-list-item-r">
                            <b>{data.ball}</b>
                        </div>
                    </div>
                );
            } else {
                meRatingNoneB =
                    <div className="meRatingNone">
                        <p>Вас нет в рейтинге</p>
                        <span>*Чтобы попасть в рейтинг, необходимо набрать хотя бы один балл</span>
                    </div>
            }
            arrBlood = this.state.arrBlood.map((data) =>
                <div className="rating-list-item" style={{ background: data.bgColor, color: data.textColor }}>
                    <div className="rating-list-item-l">
                        <div className="rating-list-item-l-l">
                            <img src={data.ava} alt="avatar" />
                        </div>
                        <div className="rating-list-item-l-r">
                            <div className="rating-list-item-l-r-t">
                                <b>{data.f_name}, {data.age} {this.declOfNum(data.age, ["год", "года", "лет"])}</b>
                            </div>
                            <div className="rating-list-item-l-r-b">
                                {data.city}
                            </div>
                        </div>
                    </div>
                    <div className="rating-list-item-r">
                        <b>{data.ball}</b>
                    </div>
                </div>
            );
        } else {
            BloodNone =
                <div className="ListNone">
                    <div className="ListNone-conent">
                        <p>Чтобы попасть в этот рейтинг, зайдите в приложение через группу, которую хотите представлять и наберите хотя бы один балл</p>
                    </div>
                </div>
        }
        //////////////////////////////////////////////////////////////////////////////////////////

        let btTitle;
        if (this.state.index == 0) { btTitle = <button onClick={this.openBdate} className="rating-title-bt">Изменить дату рождения</button> }
        if (this.state.index == 1) { btTitle = <button onClick={this.openCity} className="rating-title-bt">Изменить город</button> }
        // if (this.state.index == 2) { btTitle = <button onClick={this.openBlood} className="rating-title-bt">Изменить группу крови</button> }

        let modalR;
        if (this.state.modalR) {
            modalR =
                <div className="overlowM">
                    <div className="modalR">
                        <div className="modalR-title"><span>{this.state.modalRIn.title}</span></div>
                        <div className="modalR-content">{this.state.modalRIn.content}</div>
                        <div className="modalR-bt-block">
                            <button style={{ background: "#4879B9" }} className="modalR-bt" onClick={this.state.modalRIn.btClick}>Сохранить</button>
                            <button style={{ background: "#E05047" }} className="modalR-bt" onClick={() => { this.setState({ modalR: false }); }}>Отмена</button>
                        </div>
                    </div>
                </div>
        }

        return (
            <Panel id={this.props.id}>
                <PanelHeader
                    left={<HeaderButton onClick={() => { this.props.goBack() }} data-to="home">
                        {osname === "ios" ? <Icon28ChevronBack /> : <Icon24Back />}
                    </HeaderButton>}
                >
                    <PanelHeaderContent>
                        <div className="header-title">
                            <span>Рейтинг</span>
                        </div>
                    </PanelHeaderContent>
                </PanelHeader>
                <Div>
                    <div className="rating-title">
                        <b>Рейтинг</b>
                        {this.state.online ? btTitle : <span>Нет интернета</span>}
                        {/* {  btTitle} */}
                    </div>
                    {/* <input type="date"></input> */}
                    <div className="navi-block">
                        <div className="navi-title">
                            <div className="navi-item navi-item-1" onClick={() => { this.setState({ index: 0 }); this.changeIndex(0); }}>По возрасту</div>
                            <div className="navi-item navi-item-2" onClick={() => { this.setState({ index: 1 }); this.changeIndex(1); }}>По городу</div>
                            <div className="navi-item navi-item-3" onClick={() => { this.setState({ index: 2 }); this.changeIndex(2); }}>По сообществу</div>
                        </div>
                        <div className="navi-stick">
                            <div className="navi-stick-item"></div>
                        </div>
                    </div>
                    <div>
                        <SwipeableViews index={this.state.index} onChangeIndex={this.handleChangeIndex}>
                            <div>
                                {meRatingA}{meRatingNoneA}
                                <div id="arrAge" className="rating-list" onScroll={() => { this.scrollList("arrAge") }}>
                                    {arrAge}
                                    {arrAgeNone}
                                </div>
                            </div>
                            <div>
                                {meRatingC}{meRatingNoneC}
                                <div id="arrCity" className="rating-list" onScroll={() => { this.scrollList("arrCity") }}>
                                    {arrCity}
                                    {arrCityNone}
                                </div>
                            </div>
                            <div>
                                {meRatingB}{meRatingNoneB}
                                <div id="arrBlood" className="rating-list" onScroll={() => { this.scrollList("arrBlood") }}>
                                    {arrBlood}
                                    {BloodNone}
                                </div>
                            </div>
                        </SwipeableViews>
                    </div>

                </Div>
                {modalR}
            </Panel>
        );
    }
}

export default Main;