import React from 'react';
import connect from '@vkontakte/vkui-connect';
import { Panel, ListItem, Button, Group, Div, Avatar, PanelHeader, PanelHeaderContent, HeaderButton } from '@vkontakte/vkui';
import Icon28Menu from '@vkontakte/icons/dist/28/menu';

import '../css/Main.css';


class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            online: true,
        }
    }

    backApp = () => { this.props.history.go(-1) }

    online = () => {
        this.setState({ online: true });
    }

    offline = () => {
        this.setState({ online: false });
    }

    componentDidMount() {
        window.addEventListener('online', this.online);
        window.addEventListener('offline', this.offline);
        if (!navigator.onLine) { this.offline(); }
        try {
            document.body.style = "--header_background: #87C2E7; --header_alternate_background: #87C2E7; --background_page: #BEE4F7;";
        } catch (error) {
            console.error(error);
        }
        connect.send("VKWebAppSetViewSettings", { "status_bar_style": "dark", "action_bar_color": "#87C2E7" });
        setTimeout(() => {
            if (this.props.first) {
                this.props.chProps("first", false);
            } else {
                if (this.props.btjoingr) {
                    connect.send("VKWebAppJoinGroup", { "group_id": 21179173 });
                }
            }

        }, 100);

    }

    componentWillUnmount() {
        window.removeEventListener('online', this.online);
        window.removeEventListener('offline', this.offline);
    }

    declOfNum = (number, titles) => {
        var cases = [2, 0, 1, 1, 1, 2];
        return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }

    render() {
        let btjoingr;
        if (this.props.btjoingr) {
            btjoingr = <button className="cross-content-btCon" onClick={() => { connect.send("VKWebAppJoinGroup", { "group_id": 21179173 }); }} style={{ background: "#4779BC" }}><b>Вступить в сообщество доноров</b></button>
        } else {
            btjoingr = <div className="cross-content-btCon" style={{ background: "#4779BC" }}><a href="https://id.donorsearch.org/users/pre_sign_up" target="_blank"><b>Регистрация в DonorSearch.org</b></a></div>
        }
        return (
            <Panel id={this.props.id}>
                <PanelHeader
                    left={
                        <HeaderButton>
                            <Icon28Menu onClick={() => { this.props.showMenu ? this.props.chMenu(false) : this.props.chMenu(true) }} />
                        </HeaderButton>
                    }
                >
                    <PanelHeaderContent>
                        <div className="header-title">
                            <Avatar size={40} src={this.props.fetchedUser.photo_200 != null ? this.props.fetchedUser.photo_200 : "https://vk.com/images/camera_200.png?ava=1"} />
                            {this.props.userInfo.ball_user} {this.declOfNum(this.props.userInfo.ball_user, ["балл", "балла", "баллов"])}
                        </div>
                    </PanelHeaderContent>
                </PanelHeader>
                <Div >
                    <div className="main-title-m">
                        <div className="main-title-bl">
                            <div className="span">
                                <span>Хорошо ли ты разбираешься в донорстве? С нашим приложением ты не только проверишь себя, но и узнаешь много нового!</span>
                            </div>
                            <div className="main-bt-block">
                                <button className="cross-content-btCon" onClick={() => { this.props.goForward("crossword") }} style={{ background: "#E05047", marginRight: "0" }}><b>Кроссворд</b></button>
                                <button className="cross-content-btCon" onClick={() => { this.props.goForward("victo") }} style={{ background: "#E05047", marginLeft: "0" }}><b>Викторина</b></button>
                                <div className="cross-content-btCon" style={{ background: "#37AE46" }}><a href="https://vk.com/donorsearch?w=app5727453_-21179173" target="_blank"><b>Поддержать проект</b></a></div>
                                {btjoingr}
                            </div>
                        </div>
                    </div>
                </Div>
            </Panel>
        );
    }
}
//
export default Main;