import React from 'react';
import axios from 'axios';
import connect from '@vkontakte/vkui-connect';
import { Panel, Input, Textarea, FormLayout, PanelHeader, PanelHeaderContent, HeaderButton, platform, Div } from '@vkontakte/vkui';
import Icon28Menu from '@vkontakte/icons/dist/28/menu';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';

import '../css/NewWord.css';

const osname = platform();


class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            question: "",
            answer: "",
            status: <div></div>,
            online: true,
            nocl: true,
        }
        // this.onChange = this.onChange.bind(this);
    }

    onChange = (e) => {
        const { name, value } = e.currentTarget;
        var res = /[^а-яА-ЯїЇєЄіІёЁ0-9\W]/g.exec(value.trim);
        var a = value.replace(res, '');
        if (name == "question")
            if (a.length < 200)
                this.setState({ [name]: a });
        if (name == "answer")
            if (a.length < 20)
                this.setState({ [name]: a });
        // this.setState({ question: value });
    }

    nocl = () => {
        var self = this;
        // console.log("question", this.state.question);
        // console.log("answer", this.state.answer);
        var question = this.state.question.trim();
        var answer = this.state.answer.trim();
        this.setState({ question, answer });
        if (question.length > 2 && answer.length > 2) {
            var a = "addmoderwordcrossword" +
                '&' + String(this.props.userInfo.id) +
                '&' + String(unescape(encodeURIComponent(answer))) +
                '&' + String(unescape(encodeURIComponent(question)));
            var p = btoa(a);
            axios.get(this.props.UrlServer + '/backend/apiDonor.php', { params: { p: p, u: btoa(this.props.hr) }, })
                .then(function (response) {
                    console.log(response.data);
                    if (response.data) {
                        self.setState({ status: <div className="statusNW" style={{ color: "#37AE46" }}>Принято</div>, nocl: false });
                        setTimeout(() => { self.setState({ status: <div></div>, nocl: true, question: "", answer: "" }); }, 5000);
                    } else {
                        self.setState({ status: <div className="statusNW" style={{ color: "#E05047" }}>Отклонено</div>, nocl: false });
                        setTimeout(() => { self.setState({ status: <div></div>, nocl: true }); }, 5000);
                    }
                });
        } else {
            self.setState({ status: <div className="statusNW" style={{ color: "#E05047" }}>Отклонено</div>, nocl: false });
            setTimeout(() => { self.setState({ status: <div></div>, nocl: true }); }, 5000);
        }
    }

    online = () => {
        this.setState({ online: true });
    }

    offline = () => {
        this.setState({ online: false });
    }

    componentDidMount() {
        window.addEventListener('online', this.online);
        window.addEventListener('offline', this.offline);
        if (!navigator.onLine) { this.offline(); }
        connect.send("VKWebAppSetViewSettings", { "status_bar_style": "dark", "action_bar_color": "#87C2E7" });
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.online);
        window.removeEventListener('offline', this.offline);
    }

    render() {
        return (
            <Panel id={this.props.id}>
                <PanelHeader
                    left={<HeaderButton onClick={() => { this.props.goBack() }} data-to="home">
                        {osname === "ios" ? <Icon28ChevronBack /> : <Icon24Back />}
                    </HeaderButton>}
                >
                    <PanelHeaderContent>
                        <div className="header-title">
                            <span>Добавить слово</span>
                        </div>
                    </PanelHeaderContent>
                </PanelHeader>
                <div>
                    <FormLayout>
                        <Div className="FormNW-title"><b>Добавьте свое слово,</b></Div>
                        <Div className="FormNW-text">связанное с донорством в кроссворд, и его увидят миллионы пользователей</Div>
                        <Textarea
                            placeholder="Вопрос"
                            value={this.state.question}
                            name="question"
                            onChange={this.onChange}
                            status={this.state.question.length > 2 ? 'valid' : 'error'}
                            maxLength="200"
                        // bottom={this.state.question ? 'Электронная почта введена верно!' : 'Пожалуйста, введите электронную почту'}
                        />
                        <Input
                            type="text"
                            placeholder="Ответ"
                            value={this.state.answer}
                            name="answer"
                            onChange={this.onChange}
                            status={this.state.answer.length > 2 ? 'valid' : 'error'}
                            maxLength="20"
                        />
                        <Div>
                            <button id="FormNW-bt" className={this.state.online && this.state.nocl ? "FormNW-bt" : "FormNW-bt-none"} disabled={this.state.online && this.state.nocl ? false : true} onClick={this.nocl}>Добавить слово в кроссворд </button>
                            {this.state.online ? this.state.status : <div className="statusNW" style={{ color: "#E05047" }}>Нет интернета</div>}
                        </Div>
                        <Div className="FormNW-foo">* Ваше слово будет добавлено в массив кроссворда после проверки модератора</Div>
                    </FormLayout>
                    <div id="rock"></div>
                </div>
            </Panel>
        );
    }
}

export default Main;