import React from 'react';
import axios from 'axios';
import connect from '@vkontakte/vkui-connect';
import { Panel, ListItem, Button, Group, Div, Avatar, PanelHeader, PanelHeaderContent, HeaderButton, platform } from '@vkontakte/vkui';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import Icon24Share from '@vkontakte/icons/dist/24/share';
import Icon24Story from '@vkontakte/icons/dist/24/story';
import { ReactComponent as Vickt_bg } from '../../img/vickt_bg.svg';

import '../css/Victo.css';
const osname = platform();

const uuidv3 = require('uuid/v3');
const MY_NAMESPACE = '1c671a74-40d5-491e-99b0-da01aa1f3341';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            victblock: [],
            victNone: true,
            indexQ: 0,
            trueAnswer: 0,
            modalWin: false,
            modalNext: false,
            borColor: '',
            text: '',
            textTitel: '',
            online: true,
            first: true,
            gowall: false,
            gostory: false,
        }
    }

    startVict = () => {
        var self = this;

        var a = "getvicto" + "&" + this.props.userInfo.id;
        var p = btoa(a);
        axios
            .get(this.props.UrlServer + '/backend/apiDonor.php', { params: { p: p, u: btoa(this.props.hr) }, })
            .then(function (response) {
                var temp = [];
                var arr = response.data;
                for (let i = 0; i < arr.length; i++) {
                    var t = {};
                    t.q = arr[i].clue;
                    t.t = arr[i].text;
                    t.a1 = {}; t.a2 = {}; t.a3 = {}; t.a4 = {};
                    t.a1[1] = arr[i].A; t.a1[2] = arr[i].At;
                    t.a2[1] = arr[i].B; t.a2[2] = arr[i].Bt;
                    t.a3[1] = arr[i].C; t.a3[2] = arr[i].Ct;
                    t.a4[1] = arr[i].D; t.a4[2] = arr[i].Dt;
                    temp = temp.concat(t);
                }
                self.setState({ victblock: temp, first: false });
                self.setState({ victNone: false, indexQ: 0, trueAnswer: 0 })
            });
    }

    nextQ = (t, q, v, t1) => {
        for (let i = 0; i < v.length; i++) {
            if (v[i].v == 1) {
                document.getElementById(v[i].id).style.background = "#37AE46";
                // setTimeout(() => { document.getElementById(v[i].id).style.background = "#4779BC"; }, 500)
            }
            document.getElementById(v[i].id).setAttribute("disabled", true);
            setTimeout(() => { document.getElementById(v[i].id).removeAttribute("disabled"); }, 1050);
        }
        if (q == 1) { document.getElementById(t).style.background = "#37AE46"; } else { document.getElementById(t).style.background = "#E05047"; }
        setTimeout(() => {
            if (q == 1) {
                // setTimeout(() => { document.getElementById(t).style.background = "#4779BC"; }, 500);
                this.setState({
                    trueAnswer: this.state.trueAnswer + 1,
                    modalNext: true,
                    textTitel: 'Правильно',
                    borColor: '3px solid #37AE46',
                    text: t1,
                });
            } else {
                // setTimeout(() => { document.getElementById(t).style.background = "#4779BC"; }, 500)
                this.setState({
                    modalNext: true,
                    textTitel: 'Неправильно',
                    borColor: '3px solid #E05047',
                    text: t1,
                });
            }
        }, 1000)
    }

    openModalWin = (title, content, btClickOne, btClickOneO, btClickTwo) => {
        this.setState({
            modalWin: true,
            modalWin: {
                title,
                content,
                btClickOne,
                btClickOneO,
                btClickTwo
            }
        });
    }

    online = () => {
        this.setState({ online: true });
    }

    offline = () => {
        this.setState({ online: false });
    }

    componentDidMount() {
        window.addEventListener('online', this.online);
        window.addEventListener('offline', this.offline);
        if (!navigator.onLine) { this.offline(); }
        connect.send("VKWebAppSetViewSettings", { "status_bar_style": "dark", "action_bar_color": "#87C2E7" });
        this.startVict();
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.online);
        window.removeEventListener('offline', this.offline);
    }

    render() {

        let modalWin;
        if (this.state.modalWin) {
            modalWin =
                <div className="overlowM">
                    <div className="modalWin">
                        <div className="modalWin-title">{this.state.modalWin.title}</div>
                        <div className="modalWin-content">{this.state.modalWin.content}</div>
                        <div className="modalWin-bt-block">
                            <button disabled={this.state.gowall ? true : false} style={{ background: "#E05047" }} className={this.state.gowall ? "modalWin-bt-dis" : "modalWin-bt"} onClick={this.state.modalWin.btClickOne}>
                                <span>Поделиться на стене</span>
                                <Icon24Share />
                            </button>
                            <button disabled={this.state.gostory ? true : false} style={{ background: "#E05047" }} className={this.state.gostory ? "modalWin-bt-dis" : "modalWin-bt"} onClick={this.state.modalWin.btClickOneO}>
                                <span>Поделиться в истории</span>
                                <Icon24Story />
                            </button>
                            <button style={{ background: "#87C2E7" }} className="modalWin-bt" onClick={this.state.modalWin.btClickTwo}>Продолжить</button>
                        </div>
                    </div>
                </div>
        }
        let modalNext;
        if (this.state.modalNext) {
            modalNext =
                <div className="overlowM">
                    <div className="modalWin" style={{ border: this.state.borColor }}>
                        <h3>{this.state.textTitel}</h3>
                        <span>{this.state.text}</span>
                        <div className="modalWin-bt-block">
                            <button onClick={() => {
                                // v = ['vict-bt-bt-1', 'vict-bt-bt-2', 'vict-bt-bt-3', 'vict-bt-bt-4']
                                document.getElementById('vict-bt-bt-1').style.background = "#4779BC";
                                document.getElementById('vict-bt-bt-2').style.background = "#4779BC";
                                document.getElementById('vict-bt-bt-3').style.background = "#4779BC";
                                document.getElementById('vict-bt-bt-4').style.background = "#4779BC";


                                if (this.state.indexQ + 1 < this.state.victblock.length) {
                                    this.setState({ indexQ: this.state.indexQ + 1 });
                                } else {
                                    var self = this;
                                    var strw = "";
                                    for (let i = 0; i < 10; i++) {
                                        strw = strw + this.state.victblock[i].a1[1];
                                    }
                                    var a = "addballuser" +
                                        '&' + String(this.props.userInfo.id) +
                                        '&' + String(this.state.trueAnswer) +
                                        '&' + String("vict") +
                                        '&' + String(uuidv3(strw, MY_NAMESPACE));
                                    var p = btoa(a);
                                    axios.get(this.props.UrlServer + '/backend/apiDonor.php', { params: { p: p, u: btoa(this.props.hr) }, })
                                        .then(function (response) {
                                            if (response.data) {
                                                var ball = Number(self.props.userInfo.ball_user) + self.state.trueAnswer;
                                                var val = {
                                                    id: self.props.userInfo.id,
                                                    f_name: self.props.userInfo.f_name,
                                                    l_name: self.props.userInfo.l_name,
                                                    city: self.props.userInfo.city,
                                                    bdate: self.props.userInfo.bdate,
                                                    gropup_id: self.props.userInfo.gropup_id,
                                                    ball_user: ball
                                                }
                                                self.props.chProps("userInfo", val);
                                            }
                                        });
                                    let title =
                                        <span>
                                            {this.props.userInfo.f_name}, вы отгадали {this.state.trueAnswer} из {this.state.victblock.length} <br />
                                            <p>+{this.state.trueAnswer} балл(ов)</p>
                                        </span>;
                                    let content =
                                        <span>
                                            Вы можете стать ближе к донорству, поделившись этой записью с друзьями
                                    </span>;
                                    let btOne =
                                        () => {
                                            var text = this.state.trueAnswer + " из " + this.state.victblock.length + " в викторине о донорстве от @club21179173(DonorSearch)\nПроверь себя, насколько ты разбираешься в теме донорства."
                                            connect.send("VKWebAppShowWallPostBox", {
                                                "message": text,
                                                "attachments": "photo-182940946_457239034,https://vk.com/app7041705"
                                                // "attachments": "photo-182940946_457239033,https://vk.com/app7041705"
                                            });
                                            this.setState({ gowall: true });
                                            // this.setState({ modalWin: false, indexQ: 0, victNone: true });
                                        }
                                    let btClickOneO =
                                        () => {
                                            connect.send("VKWebAppGetAuthToken", { "app_id": 7041705, "scope": "stories" });
                                            this.setState({ gostory: true });
                                            // this.setState({ modalWin: false, indexQ: 0, victNone: true });
                                        }
                                    let btTwo =
                                        () => {
                                            this.setState({ modalWin: false, indexQ: 0, victNone: true });
                                        }
                                    this.openModalWin(title, content, btOne, btClickOneO, btTwo);

                                }

                                this.setState({ modalNext: false })
                            }}>Далее</button>
                        </div>
                    </div>
                </div>
        }

        let showBlock
        if (this.state.victNone) {
            showBlock =
                <div className="vict-start">
                    {this.state.first ? this.state.online ? <span>Loading...</span> : <span>Нет подключения к интернету</span> : this.state.online ? <button className="vict-bt-bt" onClick={this.startVict}> Начать викторину</button> : <span>Нет подключения к интернету</span>}
                    {/* {this.state.online ? <button className="vict-bt-bt" onClick={this.startVict}> Начать викторину</button> : <span>Нет подключения к интернету</span>} */}

                </div>
        } else {
            if (this.state.victblock.length != 0) {
                showBlock =
                    <div>
                        <div className="vict-v-block">
                            <div className="vict-v-title">
                                <span> {this.state.indexQ + 1} из {this.state.victblock.length} вопросов</span>
                            </div>
                            <div className="vict-v-q">
                                <b>{this.state.victblock[this.state.indexQ].q}</b>
                            </div>
                        </div>
                        <div className="vict-bt-block">
                            <button className={this.state.victblock[this.state.indexQ].a1[1].length == 0 ? "vict-bt-bt-none" : "vict-bt-bt"} id="vict-bt-bt-1" onClick={() => {
                                this.nextQ(
                                    "vict-bt-bt-1",
                                    this.state.victblock[this.state.indexQ].a1[2],
                                    [
                                        { id: "vict-bt-bt-1", v: this.state.victblock[this.state.indexQ].a1[2] },
                                        { id: "vict-bt-bt-2", v: this.state.victblock[this.state.indexQ].a2[2] },
                                        { id: "vict-bt-bt-3", v: this.state.victblock[this.state.indexQ].a3[2] },
                                        { id: "vict-bt-bt-4", v: this.state.victblock[this.state.indexQ].a4[2] },
                                    ],
                                    this.state.victblock[this.state.indexQ].t
                                )
                            }}> {this.state.victblock[this.state.indexQ].a1[1]} </button>
                            <button className={this.state.victblock[this.state.indexQ].a2[1].length == 0 ? "vict-bt-bt-none" : "vict-bt-bt"} id="vict-bt-bt-2" onClick={() => {
                                this.nextQ(
                                    "vict-bt-bt-2",
                                    this.state.victblock[this.state.indexQ].a2[2],
                                    [
                                        { id: "vict-bt-bt-1", v: this.state.victblock[this.state.indexQ].a1[2] },
                                        { id: "vict-bt-bt-2", v: this.state.victblock[this.state.indexQ].a2[2] },
                                        { id: "vict-bt-bt-3", v: this.state.victblock[this.state.indexQ].a3[2] },
                                        { id: "vict-bt-bt-4", v: this.state.victblock[this.state.indexQ].a4[2] },
                                    ],
                                    this.state.victblock[this.state.indexQ].t
                                )
                            }}> {this.state.victblock[this.state.indexQ].a2[1]} </button>
                            <button className={this.state.victblock[this.state.indexQ].a3[1].length == 0 ? "vict-bt-bt-none" : "vict-bt-bt"} id="vict-bt-bt-3" onClick={() => {
                                this.nextQ(
                                    "vict-bt-bt-3",
                                    this.state.victblock[this.state.indexQ].a3[2],
                                    [
                                        { id: "vict-bt-bt-1", v: this.state.victblock[this.state.indexQ].a1[2] },
                                        { id: "vict-bt-bt-2", v: this.state.victblock[this.state.indexQ].a2[2] },
                                        { id: "vict-bt-bt-3", v: this.state.victblock[this.state.indexQ].a3[2] },
                                        { id: "vict-bt-bt-4", v: this.state.victblock[this.state.indexQ].a4[2] },
                                    ],
                                    this.state.victblock[this.state.indexQ].t
                                )
                            }}>{this.state.victblock[this.state.indexQ].a3[1]}</button>
                            <button className={this.state.victblock[this.state.indexQ].a4[1].length == 0 ? "vict-bt-bt-none" : "vict-bt-bt"} id="vict-bt-bt-4" onClick={() => {
                                this.nextQ(
                                    "vict-bt-bt-4",
                                    this.state.victblock[this.state.indexQ].a4[2],
                                    [
                                        { id: "vict-bt-bt-1", v: this.state.victblock[this.state.indexQ].a1[2] },
                                        { id: "vict-bt-bt-2", v: this.state.victblock[this.state.indexQ].a2[2] },
                                        { id: "vict-bt-bt-3", v: this.state.victblock[this.state.indexQ].a3[2] },
                                        { id: "vict-bt-bt-4", v: this.state.victblock[this.state.indexQ].a4[2] },
                                    ],
                                    this.state.victblock[this.state.indexQ].t
                                )
                            }}> {this.state.victblock[this.state.indexQ].a4[1]} </button>
                        </div>
                    </div>
            }

        }
        return (
            <Panel id={this.props.id}>
                <PanelHeader
                    left={
                        <HeaderButton onClick={() => { this.props.goBack() }}>
                            {osname === "ios" ? <Icon28ChevronBack /> : <Icon24Back />}
                        </HeaderButton>
                    }
                >
                    <PanelHeaderContent>
                        <div className="header-title">
                            <span>Викторина</span>
                        </div>
                    </PanelHeaderContent>

                </PanelHeader>
                <div className="vict-main">
                    {showBlock}
                    <Vickt_bg className="victo-bg" />
                </div>
                {modalWin}
                {modalNext}
            </Panel>
        );
    }
}

export default Main;