import React from 'react';
import axios from 'axios';
import connect from '@vkontakte/vkui-connect';
import Select from 'react-select';
import SwipeableViews from 'react-swipeable-views';
import { Panel, ListItem, Button, Group, Div, Avatar, PanelHeader, PanelHeaderContent, HeaderButton, platform } from '@vkontakte/vkui';
import Icon28Menu from '@vkontakte/icons/dist/28/menu';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';

// import Tabletop from 'tabletop'; // google !!!!!

import '../css/First.css';

const osname = platform();
var arrAgeOhter = [];
var arrCityOhter = [];
var arrBloodOhter = [];
var myAge = null;

class Main extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			index: 0,
			arrAge: [],
			arrCity: [],
			arrBlood: [],
			modalR: false,
			gorod: '',
			meRating: '',
			userList: null,
			infoGR: {
				id: 0,
				name: "",
				ava: "https://vk.com/images/community_200.png?1",
			},
		}
	}


	componentDidMount() {
		setTimeout(() => {
			var h = document.getElementsByClassName('PanelHeader-bg'); //[0].style.boxShadow = "none!important";
			for (let i = 0; i < h.length; i++) {
				h[i].style.boxShadow = "none";
			}
		}, 10);
		document.body.style = "--header_background: #4779BC; --header_alternate_background: #4779BC; --background_page: #4779BC;"
		connect.send("VKWebAppSetViewSettings", { "status_bar_style": "light", "action_bar_color": "#4779BC" });
	}

	render() {
		return (
			<Panel id={this.props.id}>
				<PanelHeader>
				</PanelHeader>
				<Div>
					<div className="f-content">
						<div className="f-text">
							<p>Привет, {this.props.fetchedUser.first_name}!</p>
							<span>
								В сервисе "Игры для доноров" ты можешь прокачать свои знания о донорстве крови в игровой форме, а еще провести время в ожидании очереди или окончания донации в центре крови.
						</span>
						</div>
						<button className="f-bt" onClick={()=>{ this.props.chProps("activePanel", "main") }}>Играть</button>
						<div id="pes"></div>
					</div>

				</Div>
			</Panel>
		);
	}
}
export default Main;