import React from 'react';
import { Offline, Online } from "react-detect-offline";
import connect from '@vkontakte/vkui-connect';
import axios from 'axios';
// import uuid from "uuid";
import { Panel, ListItem, Button, Group, Div, Avatar, PanelHeader, PanelHeaderContent, HeaderButton, platform } from '@vkontakte/vkui';
import Icon28ChevronBack from '@vkontakte/icons/dist/28/chevron_back';
import Icon24Back from '@vkontakte/icons/dist/24/back';
import Icon24Share from '@vkontakte/icons/dist/24/share';
import Icon24Story from '@vkontakte/icons/dist/24/story';

import { ReactComponent as ArrowD } from '../../img/arrowD.svg';
import { ReactComponent as ArrowR } from '../../img/arrowR.svg';

import '../css/Crossword.css';

const uuidv3 = require('uuid/v3');
const MY_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';

const osname = platform();
const EMPTYCHAR = ' ';
let wordArray = [];
var answer = [];

// Log events flag
var logEvents = false;

// Touch Point cache
var tpCache = new Array();

class board {
    constructor(cols1, rows1) {
        this.cols = cols1;
        this.rows = rows1;
        this.activeWordList = []; //keeps array of words actually placed in board
        this.activeWordListTrue = []; //keeps array of words actually placed in board
        this.acrossCount = 0;
        this.downCount = 0;
        this.GRID_HEIGHT = cols1;
        this.GRID_WIDTH = rows1;
        this.coordList = [];
        this.grid = new Array(this.cols); //create 2 dimensional array for letter grid
        for (var i = 0; i < this.rows; i++) {
            this.grid[i] = new Array(this.rows);
        }
    }
    // (cols1, rows1) { //instantiator object for making gameboards

    suggestCoords(word) { //search for potential cross placement locations

        var c = '';
        this.coordList = [];
        var coordList = [];
        var coordCount = 0;
        for (var i = 0; i < word.length; i++) { //cycle through each character of the word
            for (var x = 0; x < this.GRID_HEIGHT; x++) {
                for (var y = 0; y < this.GRID_WIDTH; y++) {
                    c = word[i];
                    if (this.grid[x][y].targetChar == c) { //check for letter match in cell
                        if (x - i + 1 > 0 && x - i + word.length - 1 < this.GRID_HEIGHT) { //would fit vertically?
                            coordList[coordCount] = {};
                            coordList[coordCount].w = word;
                            coordList[coordCount].c = c;
                            coordList[coordCount].x = x - i;
                            coordList[coordCount].y = y;
                            coordList[coordCount].score = 0;
                            coordList[coordCount].vertical = true;
                            coordCount++;
                        }

                        if (y - i + 1 > 0 && y - i + word.length - 1 < this.GRID_WIDTH) { //would fit horizontally?
                            coordList[coordCount] = {};
                            coordList[coordCount].w = word;
                            coordList[coordCount].c = c;
                            coordList[coordCount].x = x;
                            coordList[coordCount].y = y - i;
                            coordList[coordCount].score = 0;
                            coordList[coordCount].vertical = false;
                            coordCount++;
                        }
                    }
                }
            }
        }
        this.coordList = coordList;
        coordList = null;
    }

    checkFitScore(word, x, y, vertical) {
        var fitScore = 1; //default is 1, 2+ has crosses, 0 is invalid due to collision


        if (vertical) { //vertical checking
            for (var i = 0; i < word.length; i++) {
                if (this.grid[x + word.length - 1][y].targetChar != EMPTYCHAR) {
                    fitScore = 0;
                    break;
                }
                if (i == 0 && x > 0) { //check for empty space preceeding first character of word if not on edge
                    if (this.grid[x - 1][y].targetChar != EMPTYCHAR) { //adjacent letter collision
                        fitScore = 0;
                        break;
                    }
                } else if (i == word.length - 1 && x + i < this.GRID_HEIGHT - 1) { //check for empty space after last character of word if not on edge
                    if (this.grid[x + i + 1][y].targetChar != EMPTYCHAR) { //adjacent letter collision
                        fitScore = 0;
                        break;
                    }
                }
                if (x + i < this.GRID_HEIGHT) {
                    if (this.grid[x + i][y].targetChar == word[i]) { //letter match - aka cross point
                        fitScore += 1;
                        var ex = false;
                        for (let j = 0; j < word.length; j++) {
                            if (x > 0 && x < this.GRID_HEIGHT - 1) {
                                if (this.grid[x + j][y].targetChar == word[i]) { continue; }
                                if (y > 0)
                                    if (this.grid[x + j][y - 1].targetChar != EMPTYCHAR) { ex = true; break; }
                                if (x + j < this.GRID_HEIGHT && y - 1 < this.GRID_WIDTH)
                                    if (this.grid[x + j][y + 1].targetChar != EMPTYCHAR) { ex = true; break; }
                                if (this.grid[x - 1][y + 1].targetChar != EMPTYCHAR) { ex = true; break; }
                                // if (this.grid[x + word.length - 1][y].targetChar != EMPTYCHAR) { ex = true; break; }
                            }
                        }
                        if (ex) {
                            fitScore = 0;
                            break;
                        }
                    } else if (this.grid[x + i][y].targetChar != EMPTYCHAR) { //letter doesn't match and it isn't empty so there is a collision
                        fitScore = 0;
                        break;
                    } else { //verify that there aren't letters on either side of placement if it isn't a crosspoint
                        if (y < this.GRID_WIDTH - 1) { //check right side if it isn't on the edge
                            if (this.grid[x + i][y + 1].targetChar != EMPTYCHAR) { //adjacent letter collision
                                fitScore = 0;
                                break;
                            }
                        }
                        if (y > 0) { //check left side if it isn't on the edge
                            if (this.grid[x + i][y - 1].targetChar != EMPTYCHAR) { //adjacent letter collision
                                fitScore = 0;
                                break;
                            }
                        }
                    }
                }

            }

        } else { //horizontal checking
            for (i = 0; i < word.length; i++) {
                if (this.grid[x][y + word.length - 1].targetChar != EMPTYCHAR) {
                    fitScore = 0;
                    break;
                }
                if (i == 0 && y > 0) { //check for empty space preceeding first character of word if not on edge
                    if (this.grid[x][y - 1].targetChar != EMPTYCHAR) { //adjacent letter collision
                        fitScore = 0;
                        break;
                    }
                } else if (i == word.length - 1 && y + i < this.GRID_WIDTH - 1) { //check for empty space after last character of word if not on edge
                    if (this.grid[x][y + i + 1].targetChar != EMPTYCHAR) { //adjacent letter collision
                        fitScore = 0;
                        break;
                    }
                }
                if (y + i < this.GRID_WIDTH) {
                    if (this.grid[x][y + i].targetChar == word[i]) { //letter match - aka cross point
                        fitScore += 1;
                        var ex = false;
                        for (let j = 0; j < word.length; j++) {
                            if (y > 0 && y < this.GRID_WIDTH - 1) {
                                if (this.grid[x][y + j].targetChar == word[i]) { continue; }
                                if (x > 0)
                                    if (this.grid[x - 1][y + j].targetChar != EMPTYCHAR) { ex = true; break; }
                                if (y + j < this.GRID_WIDTH && x - 1 < this.GRID_HEIGHT)
                                    if (this.grid[x + 1][y + j].targetChar != EMPTYCHAR) { ex = true; break; }
                                if (this.grid[x + 1][y - 1].targetChar != EMPTYCHAR) { ex = true; break; }
                                // if (this.grid[x][y + word.length - 1].targetChar != EMPTYCHAR) { ex = true; break; }
                            }
                        }
                        if (ex) {
                            fitScore = 0;
                            break;
                        }
                    } else if (this.grid[x][y + i].targetChar != EMPTYCHAR) { //letter doesn't match and it isn't empty so there is a collision
                        fitScore = 0;
                        break;
                    } else { //verify that there aren't letters on either side of placement if it isn't a crosspoint
                        if (x < this.GRID_HEIGHT) { //check top side if it isn't on the edge
                            if (this.grid[x + 1][y + i].targetChar != EMPTYCHAR) { //adjacent letter collision
                                fitScore = 0;
                                break;
                            }
                        }
                        if (x > 0) { //check bottom side if it isn't on the edge
                            if (this.grid[x - 1][y + i].targetChar != EMPTYCHAR) { //adjacent letter collision
                                fitScore = 0;
                                break;
                            }
                        }
                    }
                }

            }
        }

        return fitScore;
    }

    placeWord(word, clue, x, y, vertical) { //places a new active word on the board
        var wordPlaced = false;
        if (vertical) {
            if (word.length + x < this.GRID_HEIGHT) {
                for (var i = 0; i < word.length; i++) {
                    this.grid[x + i][y].targetChar = word[i];
                }
                wordPlaced = true;
            }
        } else {
            if (word.length + y < this.GRID_WIDTH) {
                for (var i = 0; i < word.length; i++) {
                    this.grid[x][y + i].targetChar = word[i];
                }
                wordPlaced = true;
            }
        }

        if (wordPlaced) {
            var currentIndex = this.activeWordList.length;
            this.activeWordList[currentIndex] = {};
            this.activeWordList[currentIndex].word = word;
            this.activeWordList[currentIndex].clue = clue;
            this.activeWordList[currentIndex].x = x;
            this.activeWordList[currentIndex].y = y;
            this.activeWordList[currentIndex].vertical = vertical;
            var currentIndexT = this.activeWordListTrue.length;
            this.activeWordListTrue[currentIndexT] = {};
            this.activeWordListTrue[currentIndexT].word = word;
            this.activeWordListTrue[currentIndexT].clue = clue;
            this.activeWordListTrue[currentIndexT].x = x;
            this.activeWordListTrue[currentIndexT].y = y;
            this.activeWordListTrue[currentIndexT].vertical = vertical;

            if (this.activeWordList[currentIndex].vertical) {
                this.downCount++;
                this.activeWordList[currentIndex].number = this.downCount;
            } else {
                this.acrossCount++;
                this.activeWordList[currentIndex].number = this.acrossCount;
            }
        }

    }

    isActiveWord(word) {
        if (this.activeWordList.length > 0) {
            for (var w = 0; w < this.activeWordList.length; w++) {
                if (word == this.activeWordList[w].word) {
                    return true;
                }
            }
        }
        return false;
    }

    displayGrid = () => {
        let a = new Array(this.cols);
        for (let i = 0; i < a.length; i++) {
            a[i] = new Array(this.rows);
        }
        for (let x = 0; x < this.grid.length; x++) {
            for (let y = 0; y < this.grid[x].length; y++) {
                a[x][y] = {};
                // a[x][y].char = this.grid[x][y].targetChar;
                a[x][y].char = EMPTYCHAR;
                if (this.grid[x][y].targetChar == EMPTYCHAR) {
                    a[x][y].style = "corss-b-n";
                } else {
                    a[x][y].style = "corss-b-y";
                }
            }
        }
        ////////////////////////////////////////////////
        for (let i = 0; i < this.activeWordListTrue.length; i++) {
            var x = this.activeWordListTrue[i].x;
            var y = this.activeWordListTrue[i].y;
            if (this.activeWordListTrue[i].vertical) {
                if (x - 1 < 0) {
                    a.unshift(new Array(this.rows));
                    for (let j = 0; j < a[0].length; j++) {
                        a[0][j] = {};
                        a[0][j].char = EMPTYCHAR;
                        a[0][j].style = "corss-b-n";
                    }
                    for (let j = 0; j < this.activeWordListTrue.length; j++) {
                        this.activeWordListTrue[j].x += 1;
                    }
                    x++;
                }
                a[x - 1][y].char = i + 1;
                a[x - 1][y].style = "corss-b-bt-v";
                a[x - 1][y].bt = true;
            } else {
                if (y - 1 < 0) {
                    for (let j = 0; j < a.length; j++) {
                        a[j].unshift(
                            {
                                char: EMPTYCHAR,
                                style: "corss-b-n"
                            }
                        );


                    }
                    for (let j = 0; j < this.activeWordListTrue.length; j++) {
                        this.activeWordListTrue[j].y += 1;
                    }
                    y++;
                }
                a[x][y - 1].char = i + 1;
                a[x][y - 1].style = "corss-b-bt-h";
                a[x][y - 1].bt = true;
            }
        }
        this.grid = null;
        setTimeout(() => { a = null }, 500)
        return a;
    }

    getActiveWord = () => {
        return this.activeWordListTrue;
    }

    generateBoard = (seed = 0) => {

        var bestScoreIndex = 0;
        var top = 0;
        var fitScore = 0;
        var startTime;
        this.activeWordListTrue = [];

        for (var x = 0; x < this.cols; x++) {
            for (var y = 0; y < this.rows; y++) {
                this.grid[x][y] = {};
                this.grid[x][y].targetChar = EMPTYCHAR;
                this.grid[x][y].indexDisplay = '';
                this.grid[x][y].value = '-';
            }
        }

        this.placeWord(wordArray[seed].word, wordArray[seed].clue, 8, 4, false);


        for (var iy = 0; iy < 10; iy++) {
            for (var ix = seed + 1; ix < wordArray.length; ix++) {
                if (!this.isActiveWord(wordArray[ix].word)) {
                    var topScore = 0;
                    bestScoreIndex = 0;
                    this.suggestCoords(wordArray[ix].word);
                    this.coordList.sort(() => { return Math.random() - 0.5 });

                    if (this.coordList[0]) {
                        for (var c = 0; c < this.coordList.length; c++) {
                            fitScore = this.checkFitScore(wordArray[ix].word, this.coordList[c].x, this.coordList[c].y, this.coordList[c].vertical);
                            if (fitScore > topScore) {
                                topScore = fitScore;
                                bestScoreIndex = c;
                            }
                        }
                    }

                    if (topScore > 1) {
                        this.placeWord(wordArray[ix].word, wordArray[ix].clue, this.coordList[bestScoreIndex].x, this.coordList[bestScoreIndex].y, this.coordList[bestScoreIndex].vertical);
                    }
                }

            }
        }
        if (this.activeWordList.length < wordArray.length / 4) { //regenerate board if if less than half the words were placed
            seed++;
            this.generateBoard(seed);
        }
    }
}

class Crossword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            getCrossword: false,
            crossWordTable: null,
            clue: '',
            answer: [],
            trueAnswer: 0,
            online: true,
            gowall: false,
            gostory: false,
        }
    }

    onChange = (e) => {
        const { id, value } = e.currentTarget;
        var w = this.state.activeW[id - 1].word
        var x = this.state.activeW[id - 1].x;
        var y = this.state.activeW[id - 1].y;
        var v = this.state.activeW[id - 1].vertical
        var res = /[^а-яА-ЯїЇєЄіІёЁ\w]/g.exec(value);
        var a = value.replace(res, '');
        var tempV = a.toUpperCase();
        if (tempV.length <= w.length) {
            answer[id] = tempV;
            var tempCross = this.state.crossWordTable;
            if (v) {
                if (tempV.length != 0) {
                    tempCross[x + tempV.length - 1][y].char = tempV[tempV.length - 1];
                }
                for (let i = 0; i < w.length; i++) {
                    if (tempV.length - 1 < i) {
                        tempCross[x + i][y].char = EMPTYCHAR;
                    }
                }
            } else {
                if (tempV.length != 0) {
                    tempCross[x][y + tempV.length - 1].char = tempV[tempV.length - 1];
                }
                for (let i = 0; i < w.length; i++) {
                    if (tempV.length - 1 < i) {
                        tempCross[x][y + i].char = EMPTYCHAR;
                    }
                }
            }

            this.setState({ crossWordTable: tempCross });
        }
    }

    getCrossword = () => {
        var self = this;
        answer = [];
        this.setState({ clue: '', trueAnswer: 0 });
        //////////////////////////////////////////////////////////  https://app3.vk-irs.ru/backend/test.php
        var a = "getcrossword" + "&" + this.props.userInfo.id;
        var p = btoa(a);
        axios
            .get(this.props.UrlServer + '/backend/apiDonor.php', { params: { p: p, u: btoa(this.props.hr) }, })
            .then(function (response) {
                wordArray = response.data;
                var gameboard = new board(20, 20);
                gameboard.generateBoard();
                var temp = gameboard.displayGrid();
                var activeW = gameboard.getActiveWord();
                self.setState({
                    activeW: activeW,
                    crossWordTable: temp,
                    getCrossword: true
                });
                gameboard = null;
                document.getElementById("table-block").scrollTop = 0;
                document.getElementById("table-block").scrollLeft = 0;
            });
        //////////////////////////////////////////////////////////
    }

    online = () => {
        if (this.state.crossWordTable == null) {
            this.getCrossword();
        }
        setTimeout(() => {
            var h = document.getElementById("cross-content").clientHeight - 15;
            document.getElementById("cross-content").style.minHeight = h + "px";
        }, 500);
        this.setState({ online: true });
    }

    offline = () => {
        this.setState({ online: false });
    }

    componentDidMount() {
        window.addEventListener('online', this.online);
        window.addEventListener('offline', this.offline);
        if (!navigator.onLine) { this.offline(); }
        connect.send("VKWebAppSetViewSettings", { "status_bar_style": "dark", "action_bar_color": "#87C2E7" });
        this.getCrossword();
        setTimeout(() => {
            var h = document.getElementById("cross-content").clientHeight - 15;
            document.getElementById("cross-content").style.minHeight = h + "px";
        }, 500);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.online);
        window.removeEventListener('offline', this.offline);
    }

    declOfNum = (number, titles) => {
        var cases = [2, 0, 1, 1, 1, 2];
        return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }

    goAnswer = () => {
        var trueA = 0
        var strw = "";
        for (let i = 0; i < this.state.activeW.length; i++) {
            if (answer.indexOf(this.state.activeW[i].word) != -1) { trueA++; }
            strw = strw + this.state.activeW[i].word;
        }
        this.setState({ trueAnswer: trueA });
        var self = this;
        var a = "addballuser" +
            '&' + String(this.props.userInfo.id) +
            '&' + String(trueA) +
            '&' + String("cross") +
            '&' + String(uuidv3(strw, MY_NAMESPACE));
        var p = btoa(a);
        axios.get(this.props.UrlServer + '/backend/apiDonor.php', { params: { p: p, u: btoa(this.props.hr) }, })
            .then(function (response) {
                if (response.data) {
                    var ball = Number(self.props.userInfo.ball_user) + trueA;
                    var val = {
                        id: self.props.userInfo.id,
                        f_name: self.props.userInfo.f_name,
                        l_name: self.props.userInfo.l_name,
                        city: self.props.userInfo.city,
                        bdate: self.props.userInfo.bdate,
                        gropup_id: self.props.userInfo.gropup_id,
                        ball_user: ball
                    }
                    self.props.chProps("userInfo", val);
                }
            });
        let title =
            <span>
                {this.props.userInfo.f_name}, вы отгадали {trueA} из {this.state.activeW.length} <br />
                <p>+{trueA} балл(ов)</p>
            </span>;
        let content =
            <span>
                Вы можете стать ближе к донорству, поделившись этой записью с друзьями
            </span>;
        let btOne =
            () => {
                var text = "Я разгадал кроссворд о донорстве от @club21179173(DonorSearch) - попробуй и ты.\nУзнай, насколько ты разбираешься в теме донорства."
                connect.send("VKWebAppShowWallPostBox", {
                    "message": text,
                    "attachments": "photo-182940946_457239034,https://vk.com/app7041705"
                    // "attachments": "photo-182940946_457239033,https://vk.com/app7041705"
                });
                this.setState({ gowall: true });
                // this.getCrossword();
                // this.setState({ modalWin: false, indexQ: 0, victNone: true });
            }
        let btClickOneO =
            () => {
                connect.send("VKWebAppGetAuthToken", { "app_id": 7041705, "scope": "stories" });
                this.setState({ gostory: true });
                // this.getCrossword();
                // this.setState({ modalWin: false, indexQ: 0, victNone: true });
            }
        let btTwo =
            () => {
                this.getCrossword();
                this.setState({ modalWin: false, indexQ: 0, victNone: true });
            }
        this.openModalWin(title, content, btOne, btClickOneO, btTwo);
    }

    openModalWin = (title, content, btClickOne, btClickOneO, btClickTwo) => {
        this.setState({
            modalWin: true,
            modalWin: {
                title,
                content,
                btClickOne,
                btClickOneO,
                btClickTwo
            }
        });
    }

    geuClue = (val) => {
        this.setState({ clue: val + ". " + this.state.activeW[val - 1].clue + "(" + this.state.activeW[val - 1].word.length + " " + this.declOfNum(this.state.activeW[val - 1].word.length, ["буква", "буквы", "букв"]) + ")" });
    }

    focusInput = (id) => {
        var inp = document.getElementById(id);
        if (inp.createTextRange) {
            var part = inp.createTextRange();
            part.move("character", 0);
            part.select();
        } else if (inp.setSelectionRange) {
            inp.setSelectionRange(inp.value.length, inp.value.length);
        }
        inp.focus();
    }

    render() {
        let content;
        if (this.state.getCrossword) {
            let crossWordTable1
            if (this.state.crossWordTable != null) {
                crossWordTable1 = this.state.crossWordTable.map((data) => {
                    var el = []
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].bt) {
                            var arrow;
                            if (data[i].style == "corss-b-bt-v") {
                                arrow = <ArrowD className="arrow-down" />;
                            }
                            if (data[i].style == "corss-b-bt-h") {
                                arrow = <ArrowR className="arrow-right" />;
                            }
                            el = el.concat(
                                <button onClick={() => { this.geuClue(data[i].char) }}>
                                    <input autocomplete="off" onClick={() => { this.focusInput(data[i].char) }} id={data[i].char} value={answer[data[i].char]} onChange={this.onChange}></input>
                                    <div>
                                        <span>{data[i].char}</span>
                                        {arrow}
                                    </div>
                                </button>
                            )
                        } else {
                            el = el.concat(
                                <div>{data[i].char}</div>
                            )
                        }
                    }

                    return <tr>
                        <td className={data[0].style}>{el[0]}</td>
                        <td className={data[1].style}>{el[1]}</td>
                        <td className={data[2].style}>{el[2]}</td>
                        <td className={data[3].style}>{el[3]}</td>
                        <td className={data[4].style}>{el[4]}</td>
                        <td className={data[5].style}>{el[5]}</td>
                        <td className={data[6].style}>{el[6]}</td>
                        <td className={data[7].style}>{el[7]}</td>
                        <td className={data[8].style}>{el[8]}</td>
                        <td className={data[9].style}>{el[9]}</td>
                        <td className={data[10].style}>{el[10]}</td>
                        <td className={data[11].style}>{el[11]}</td>
                        <td className={data[12].style}>{el[12]}</td>
                        <td className={data[13].style}>{el[13]}</td>
                        <td className={data[14].style}>{el[14]}</td>
                        <td className={data[15].style}>{el[15]}</td>
                        <td className={data[16].style}>{el[16]}</td>
                        <td className={data[17].style}>{el[17]}</td>
                        <td className={data[18].style}>{el[18]}</td>
                        <td className={data[19].style}>{el[19]}</td>
                    </tr>
                });

            }
            content =
                <div className="cross-content" id="cross-content">
                    {/* <div onTouchMove={this.move_handler} onTouchStart={this.start_handler} onTouchEnd={this.end_handler} style={{ overflow: "auto",  width: "100%", touchAction: "manipulation", transform: "scale(1)" }}> */}
                    <div className="cross-block">
                        <b>{this.state.clue}</b>
                        <div id="table-block">
                            <table id="table">
                                {crossWordTable1}
                            </table>
                        </div>
                    </div>
                    <div className="cross-content-btCon-block" style={{ width: "100%", marginBottom: "15px" }}>
                        <button className={this.state.online ? "cross-content-btCon" : "cross-content-btCon-none"} disabled={this.state.online ? false : true} onClick={this.goAnswer} style={{ background: "#E05047" }}><b>Ответить</b></button>
                        <button className={this.state.online ? "cross-content-btCon" : "cross-content-btCon-none"} disabled={this.state.online ? false : true} onClick={this.getCrossword} style={{ background: "#4879B9" }}><b>Поменять кроссворд</b></button>
                    </div>
                </div >
        } else {
            content =
                <div className="cross-content" id="cross-content">
                    {/* <button className="cross-content-btCon" onClick={this.getCrossword}>qwe</button> //  */}
                    <span>{this.state.online ? "Loading..." : "Нет подключения к интернету"}</span>
                </div>
        }

        let modalWin;
        if (this.state.modalWin) {
            modalWin =
                <div className="overlowM">
                    <div className="modalWin">
                        <div className="modalWin-title">{this.state.modalWin.title}</div>
                        <div className="modalWin-content">{this.state.modalWin.content}</div>
                        <div className="modalWin-bt-block">
                            <button disabled={this.state.gowall ? true : false} style={{ background: "#E05047" }} className={this.state.gowall ? "modalWin-bt-dis" : "modalWin-bt"} onClick={this.state.modalWin.btClickOne}>
                                <span>Поделиться на стене</span>
                                <Icon24Share />
                            </button>
                            <button disabled={this.state.gostory ? true : false} style={{ background: "#E05047" }} className={this.state.gostory ? "modalWin-bt-dis" : "modalWin-bt"} onClick={this.state.modalWin.btClickOneO}>
                                <span>Поделиться в истории</span>
                                <Icon24Story />
                            </button>
                            <button style={{ background: "#87C2E7" }} className="modalWin-bt" onClick={this.state.modalWin.btClickTwo}>Продолжить</button>
                        </div>
                    </div>
                </div>
        }

        return (
            <Panel id={this.props.id}>
                <PanelHeader
                    left={<HeaderButton onClick={() => { this.props.goBack() }} data-to="home">
                        {osname === "ios" ? <Icon28ChevronBack /> : <Icon24Back />}
                    </HeaderButton>}
                >
                    <PanelHeaderContent>
                        <div className="header-title">
                            <span>Кроссворд</span>
                        </div>
                    </PanelHeaderContent>
                </PanelHeader>
                <Div>
                    {content}
                </Div>
                {modalWin}
            </Panel>
        );
    }
}

export default Crossword;